{
    class VaultController {

        static get $inject() {
            return ['$scope', 'VaultService'];
        }

        constructor($scope, VaultService) {
        }
    }

    angular.module('adminApp')
        .controller('VaultController', VaultController);
}
